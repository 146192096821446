import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/poradnik/#dzieci"> &gt; Poradnik(Dzieci)</a>
            <a href="/poradnik/dzieci/zatkany_nos_u_dziecka/" className="sel">
              {" "}
              &gt; Zatkany nos u dziecka
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/GettyImages-135538267.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Zatkany nos u dziecka</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    <b>
                      Nieżyt nosa (potocznie zwany katarem ) to jedna z
                      najczęściej pojawiających się dolegliwości, z którą
                      zmagamy się co najmniej kilka razy w roku. Dzieci „łapią”
                      katar zdecydowanie szybciej niż dorośli, a jego przebieg
                      znoszą znacznie gorzej. Utrudnione oddychanie, zablokowany
                      nos oraz niespokojny, ciągle przerywany niedrożnością
                      nosa, sen to część problemów, z którymi zmagają się dzieci
                      i ich rodzice podczas infekcji. Jakie więc działania
                      należy podjąć, by złagodzić te objawy?
                    </b>
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Katar jest najczęściej zwiastunem{" "}
                  <a href="/slowniczek/#przeziebienie" target="_blank">
                    przeziębienia
                  </a>
                  , reakcji alergicznej, albo grypy. Rozpoczyna się od kichania,
                  uczucia zatkania nosa, następnie pojawia się wodnista{" "}
                  <a href="/slowniczek/#wydzielina_z_nosa" target="_blank">
                    wydzielina,
                  </a>{" "}
                  która po około czterech dniach gęstnieje. Często takim objawom
                  towarzyszy zaburzenie węchu, nie czujemy zapachu i smaku
                  potraw, nie mamy apetytu, stajemy się drażliwi i mamy coraz
                  gorsze samopoczucie. Tych dolegliwości nie należy także
                  lekceważyć u dzieci. &nbsp; &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    Dzieci mogą zmagać się z katarem wirusowym (przechodzą go
                    kilka razy w roku, gdyż ich{" "}
                  </span>
                  <a
                    href="/slowniczek/#uklad_immunologiczny"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    układ immunologiczny
                  </a>
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    {" "}
                    nie jest w pełni ukształtowany) lub bakteryjnym, inaczej
                    nazywanym ropnym (objawia się biało-żółtą lub zieloną
                    wydzieliną i wymaga zastosowania terapii antybiotykowej).{" "}
                  </span>
                  <a
                    href="/slowniczek/#niezyt_nosa"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    Nieżyt nosa
                  </a>
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    {" "}
                    można leczyć kilkoma domowymi sposobami (np. ziołowymi
                    inhalacjami, mlekiem z miodem, czosnkiem lub imbirem), albo
                    preparatami obkurczającymi śluzówkę nosa zawierającymi{" "}
                  </span>
                  <a
                    href="/slowniczek/#ksylometazolina"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    ksylometazolinę
                  </a>
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    {" "}
                    np.{" "}
                  </span>
                  <a
                    href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    Sudafed<sup>®</sup> Xylospray HA{" "}
                  </a>
                  &nbsp;
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    dla dzieci. Areozol do nosa{" "}
                  </span>
                  <a
                    href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    Sudafed<sup>®</sup> Xylospray HA
                  </a>
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    {" "}
                    dla dzieci pomaga usunąć zalegającą wydzielinę poprzez
                    obkurczające działanie na naczynia krwionośne i zmniejszenie
                    obrzęku błony śluzowej nosa, co ułatwia oddychanie. Co ważne
                    w przypadku delikatnych nosków dzieci, nie zawiera
                    konserwantów, dzięki czemu nie podrażnia ich, a także nie
                    nasila objawów alergicznego nieżytu nosa, które mogą być
                    wywoływane przez konserwanty<sup>1</sup>.
                  </span>
                </div>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt
                    src="/web/kcfinder/uploads/images/shutterstock_286005839.jpg"
                    style={{
                      width: "380px",
                      height: "253px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "left",
                      margin: "5px 10px 10px 0px",
                      border: "2px solid #fff",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  Gdy mamy w domu chore dziecko (ale nie tylko wtedy) warto
                  pamiętać o regularnym wietrzeniu mieszkania i zapewnianiu
                  odpowiedniej wilgotności powietrza w mieszkaniu (sprawdzą się
                  tutaj nawilżacze czy mokry ręcznik na kaloryferze). W uporaniu
                  się z katarem pomoże też sól fizjologiczna lub woda morska w
                  aerozolu która rozrzedzi wydzielinę, maść majerankowa
                  posiadająca właściwości przeciwzapalne
                  i&nbsp;przeciwbakteryjne, a także regularne czyszczenie nosa
                  (np. u małych dzieci za pomocą gruszki lub aspiratora).
                  Niemniej istotne jest przyjmowanie dużej ilości płynów (wody).
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span className="s1">
                    Podczas sezonu jesienno-zimowego szczególną opieką
                    powinniśmy otoczyć dzieci do 12 roku życia, u których układ
                    immunologiczny nie jest w pełni ukształtowany. Katar
                    pojawiający się u dzieci w tym wieku niesie ze sobą duże
                    ryzyko powikłań, np. zapalenie ucha. Inna budowa{" "}
                    <a href="/slowniczek/#trabka_sluchowa" target="_blank">
                      trąbki Eustachiusza
                    </a>
                    &nbsp;u maluchów, niż u dorosłych zwiększa podatność
                    zachorowania na zapalenie ucha.&nbsp;
                  </span>
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <img
                    alt="Domowe sposoby czasami nie pomagają w walce z zatkanym nosem u dziecka."
                    src="/web/kcfinder/uploads/images/shutterstock_93339907.jpg"
                    style={{
                      width: "280px",
                      height: "300px",
                      "border-width": "2px",
                      "border-style": "solid",
                      float: "right",
                      margin: "5px 0px 10px 10px",
                      border: "2px solid #fff",
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    Trąbka słuchowa, która łączy ucho środkowe z górną częścią
                    gardła jest u dzieci szeroka, bardziej pozioma i nieustannie
                    otwarta, stąd też przedostanie się do niej zalegającej{" "}
                  </span>
                  <a
                    href="/slowniczek/#wydzielina_z_nosa"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    wydzieliny
                  </a>
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    {" "}
                    następuje bardzo szybko. By temu zapobiec, warto zastosować
                    preparat{" "}
                  </span>
                  <a
                    href="/produkty/sudafed_xylospray_dla_dzieci/"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    Sudafed<sup>®</sup> XyloSpray
                  </a>
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    {" "}
                    lub{" "}
                  </span>
                  <a
                    href="/produkty/sudafed_xylospray_ha_dla_dzieci/"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    Sudafed<sup>®</sup> Xylospray HA
                  </a>
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    {" "}
                    dla dzieci. Te produkty, dzięki zawartej w nich{" "}
                  </span>
                  <a
                    href="/slowniczek/#ksylometazolina"
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                    target="_blank"
                  >
                    ksylometazolinie
                  </a>
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify",
                    }}
                  >
                    , obkurczają&nbsp; śluzówkę nosa, zapobiegają zaleganiu
                    wydzieliny w trąbce słuchowej i ułatwiają jej odpływ.&nbsp;
                    Dzięki temu zmniejsza się ryzyko nadkażenia.
                  </span>
                </div>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <div>&nbsp;</div>
                <div>
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    1 Lebe E, Baka M, Yavaşoğlu A, Aktuğ H, Ateş U, Uyanikgil Y.
                    Effects of preservatives in nasal formulations on the
                    mucosal integrity: an electron microscopic study.
                    Pharmacology. 2004 Oct;72(2):113-20.
                  </span>
                </div>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">Bibliografia:&nbsp;</span>
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Górnicka Jadwiga,&nbsp;
                    <i>Nie daj się przeziębieniu! Receptury natury</i>, AWM More
                    <a href="http://www.gandalf.com.pl/w/awm-morex/">
                      <span className="s2">x</span>
                    </a>
                    , 2014.
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Iwanowicz-Palus Grażyna,&nbsp;
                    <em>Ciąża, poród, macierzyństwo</em>, Wydawnictwo Rea, 2013
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify",
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    Latkowski Bożydar,&nbsp;
                    <i>Farmakoterapia w otorynolaryngologii,</i>&nbsp; PZWL.
                  </span>
                </p>
                <p className="p1">
                  <span
                    style={{
                      "font-size": "11px",
                    }}
                  >
                    <span className="s1">
                      Tylżanowska-Kisiel Joanna,&nbsp;
                      <i>Przeziębienie i grypa</i>, Helion, 2007.
                    </span>
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Podobne artykuły</p>
                <a
                  href="/poradnik/dzieci/odpornosc_dziecka_-_zadbaj_i_zwiekszaj"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_1668728821.jpg")',
                    }}
                  />
                  <span className="title">
                    Odporność dziecka - zadbaj i zwiększaj
                  </span>
                </a>
                <a
                  href="/poradnik/dzieci/walka_z_infekcjami_-_katar_i_kaszel_u_dziecka_"
                  className="box"
                >
                  <span
                    className="photo"
                    style={{
                      backgroundImage:
                        'url("/web/kcfinder/uploads/files/shutterstock_494479543.jpg")',
                    }}
                  />
                  <span className="title">
                    Walka z infekcjami - katar i kaszel u dziecka{" "}
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px",
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a
                    id="ot-sdk-btn"
                    className="ot-sdk-show-settings"
                    rel="nofollow"
                  >
                    {" "}
                    Ustawienia plików Cookie{" "}
                  </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty. 2023
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
